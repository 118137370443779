.modal-confirm {
  .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop{
    opacity: 0.2!important;
  }
  &__box {
    background: white;
    margin: 20rem auto 0 auto;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
    line-height: 140%;

    width: 24.75rem;
    max-height: 13rem;
    border-radius: var(--border-radius);
  }

  &__group-btn {
    margin-top: 1.5rem;
    text-align: right;
    button {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;

      cursor: pointer;
      padding: 8px 24px;
      border-radius: var(--border-radius-component);
      border: 1px solid #edf0f6;
    }
  }
  &__dismiss {
    background: var(--white-color);
    margin-right: 12px;
    &:hover {
      color: var(--bg-pr-large-hover);
      background: var(--white-color);
    }
  }
  &__save {
    font-weight: 600;
    color: var(--white-color);
    background: var(--bg-pr-large-default) !important;
    border: 1px solid var(--bg-pr-large-default) !important;
    &:hover {
      background: var(--bg-pr-large-hover) !important;
      color: var(--white-color);
    }
  }
}
.modal-print-template-confirm {
  &__box {
    background: white;
    margin: 20rem auto 0 auto;
    padding: 1.5rem;
    line-height: 140%;

    width: 30rem;
    max-height: 13.5rem;
    border-radius: var(--border-radius);
  }

  &__title {
    //width: 12.625rem;
    height: 1.75rem;
    font-style: normal;
    font-weight: 600;

    font-size: 1.25rem;
    line-height: 140%;
    color: #00081D;
    margin-bottom: 1.5rem;
  }

  &__sub-title {
    width: 27rem;
    height: 2.5rem;

    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    line-height: 140%;
    color: #00081D;
  }

  &__group-btn {
    margin-top: 1.5rem;
    text-align: right;
    button {
      font-weight: 400;
      max-height: 2rem;
      font-size: 15px;
      line-height: 100%;
      width: 6.875rem;

      cursor: pointer;
      padding: 8px 24px;
      border-radius: var(--border-radius-component);
      border: 1px solid #edf0f6;
    }
  }
  &__dismiss {
    background: var(--white-color);
    margin-right: 12px;
    &:hover {
      color: var(--bg-pr-large-hover);
      background: var(--white-color);
    }
  }
  &__save {
    font-weight: 600;
    color: var(--white-color);
    background: var(--bg-pr-large-default) !important;
    border: 1px solid var(--bg-pr-large-default) !important;
    &:hover {
      background: var(--bg-pr-large-hover) !important;
      color: var(--white-color);
    }
  }
}