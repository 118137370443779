.common-tooltip-v2 {
  max-width: 350px;

  .MuiTooltip-tooltip {
    padding: 4px 12px;

    backdrop-filter: blur(40px);
    background: rgba(0, 31, 62, 0.95);
    border-radius: 6px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);

    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;

    .MuiTooltip-arrow {
      &:before {
        background: rgba(0, 31, 62, 0.95);
      }
    }
  }
}

.common-popover {
  .MuiPaper-root {
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
  }
}

.popup-tooltipV2 {
  max-width: unset !important;
  .MuiTooltip-tooltip {
    max-width: unset !important;
  }
}