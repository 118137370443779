.switch {
  position: relative;
  display: inline-block;
  width: 33.33px;
  height: 20px;
  cursor: pointer;
  &[data-disabled = 'true'] {
    cursor: not-allowed;
  }
  input {
    opacity: 0;
    width: 33.33px;
    height: 20px;



    &:checked + .slider {
      background-color: #3264FF;
    }

    &:checked:disabled + .slider {
      cursor: not-allowed;
      background-color: #3264FF;
      opacity: 0.7
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }

  .slider {
    position: absolute;
    //cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;

    &::before {
      position: absolute;
      content: "";
      width: 16.67px;
      height: 16.67px;
      left: 1.67px;
      bottom: 1.67px;
      right: 1.67px;
      background-color: white;
      transition: 0.4s;
      border-radius: 34px;

    }
  }

  .round {
    &::before {
      border-radius: 50%;
    }
  }

}