.group-login-form {
  animation-name: slideFromBottom;
  animation-duration: 1s;
}

@keyframes slideFromBottom {
  0% {
    left: 0px;
  }
  100% {
    left: 145px;
  }
}

.number-box{
  display: flex;
  align-items: center;
}

.otp {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  color: #0052ff;
  position: absolute;
  right: 1rem;
  cursor: pointer;
}

.otp-input {
  margin-top: 18px;
  margin-bottom: 8px;
}

.otp-60s {
  color: red;
}

.send-otp-disabled {
  color: gray;
  cursor: unset;
}

.pass-new-field {
  width: 446px;
  height: 115px;
  position: relative;
}

.group-inpput-login-form{
  display: flex;
  align-items: center;
  position: relative;
}

.acp-password-new-field {
  width: 446px;
  height: 90px;
  position: relative;
}

.password-note {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* margin-top: 8px; */
}

#phone-number,
#password,
#password-new {
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dde2f0;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 13px;
  animation-name: opacityAnim;
  animation-duration: 1s;
  opacity: 1;
}

#phone-number {
  padding-right: 13px;
  margin: 0.6rem 0;
}

#password,
#password-new {
  padding-right: 44px;
}

#phone-number:focus,
#password:focus,
#password-new:focus {
  outline: none;
  border: 1px solid #1086E9;
  box-shadow: 0px 0px 0px 3px rgba(43, 184, 169, 0.25);
}

#phone-number::placeholder,
#password::placeholder {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.004em;
  color: #7d9ab5;
  line-height: 52px;
  opacity: 0.5;
}

#password,
#password-new {
  background-repeat: no-repeat;
  background-position: left;
  background-position-x: 16px;
  margin: 8px 0;
}

@media only screen and (max-width: 1440px) {
  #phone-number,
  #password,
  #password-new {
    width: 384px;
  }

  .acp-password-new-field,
  .pass-new-field {
    width: 384px;
  }
}
