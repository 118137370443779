.drop-edit {
    display: flex;
    align-items: center;

    img {
      margin-right: 0.75rem;
    }
    span {
    
        &:hover {
          color: #1086E9;
        }
      }
    &:hover {
      color: #1086E9;
      opacity: 0.7;
    }
  }