.user-management-confirm{
    width: 480px;
    height: 204px;
    background: #FFFFFF;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 24px;
    position: relative;
    top: 20rem;
    left: 48rem;
    &_text {
        margin-top: 24px;
        margin-bottom: 24px;
    }

    &_button {
        text-align: right;
    }

    &_cancel {
        width: 110px;
        font-size: 14px !important;
        height: 32px !important;
        line-height: 30px !important;
        // border: 1px solid #EBEEF5;
        // border-radius: 6px;
    }

    &_acept {
        width: 110px;
        font-size: 14px !important;
        margin-left: 8px;
        height: 32px !important;
        line-height: 30px !important;
    }
}
@media only screen and (max-width : 1280px ){
    .user-management-confirm{
        top: 20rem;
        left: 33rem;
    }
}
@media only screen and (max-width : 1366px ){
    .user-management-confirm{
        top: 20rem;
        left: 35rem;
    }
}
@media only screen and (max-width : 1440px ){
    .user-management-confirm{
        left:30rem;
    }
}