.accept {
    background-color: green;
}

.delete {
    background-color: red;
}

.custorm-confirm-message-delivery {
    width: 480px;
    // height: 184px;

    background: #FFFFFF;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    padding: 1.5rem;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    text-decoration: none;

    p {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        color: #00081D;

        margin-bottom: 1.5rem;
    }

    .description {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #00081D;
    }

    .custorm-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        margin-top: 2rem;

        .custorm-btn-close {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 110px;
            height: 32px;

            border: 1px solid #EBEEF5;
            border-radius: 6px;

            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #151624;
            text-transform: none;

            margin-right: 0.5rem;

            &:hover {
                color: #1E9A98;
            }
        }

        .custorm-btn-accept {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 110px;
            height: 32px;

            // background: #FF424E;
            border-radius: 6px;

            font-weight: 600;
            font-size: 14px;
            line-height: 140%;
            color: #FFFFFF;
            text-transform: none;
        }

        .accept {
            background: #1E9A98;

            &:hover {
                background: rgba(30, 154, 152, 0.8);
            }
        }

        .delete {
            background: #FF424E;

            &:hover {
                background: #FF7471;
            }
        }
    }
}